import React, { Component } from 'react';
import { render } from 'react-dom';
import { Row, Col } from 'reactstrap';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import './Tdslightbox.css';

const images = [
  {
    src: require('./../images/pic_8.jpg'),
    title: 'Product Design',
    description:
      'Thanks to your story, we create it an ideal brand image for your company. We create a brand image that will arouse the interest of your customers. A good logo represents your company and brand values.',
  },
  // {
  //   src: require('./../images/350x261.jpg'),
  //   title: 'Branding',
  //   description: 'description',
  // },
  {
    src: require('./../images/pic_1.jpg'),
    title: 'Search Engine Optimization',
    description:
      'Search engine optimization is essential for creating an effective and successful brand image. Africongo possesses the skills and techniques that aim to improve the positioning of a page, you get the first places in visibility on search engines. Your future customers spot you quickly and systematically.',
  },
  // {
  //   src: require('./../images/350x261.jpg'),
  //   title: 'Social Media marketing',
  //   description: 'description',
  // },

  {
    src: require('./../images/pic_3.jpg'),
    title: 'Digital marketing',
    description:
      'We develop your customer base through the creation of highly targeted databases and the establishment of a unique communication process.',
  },
  {
    src: require('./../images/350x261.jpg'),
    title: 'UI/UX Design',
    description:
      'Our team of UX and UI specialists work to design creative and original interfaces that match the functional specifications of your website or application. We specialize in designing the user experience of web platforms and mobile applications.',
  },
];

class Tdslightbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }
  render() {
    const { photoIndex, isOpen } = this.state;

    let names = ['wood', 'sun'];
    let image_array = images.map((image, key) => {
      return (
        <Col sm="6" md="3" key={key}>
          <div className="portfolio-box">
            <div className="portfolio-img">
              <img
                src={image.src}
                alt=""
                className="img-fluid"
                onClick={() => this.setState({ isOpen: true, photoIndex: key })}
                width="350px"
                height="261px"
              />
            </div>
            <h5 className="mt-3"> {image.title} </h5>
            <p className="mt-3"> {image.description} </p>
          </div>
        </Col>
      );
    });

    return (
      <div>
        <Row>{image_array}</Row>

        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]['src']}
            nextSrc={images[(photoIndex + 1) % images.length]['src']}
            prevSrc={
              images[(photoIndex + images.length - 1) % images.length]['src']
            }
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        )}
      </div>
    );
  }
}

export default Tdslightbox;
