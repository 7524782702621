/**
 * Entry application component used to compose providers and render Routes...
 * */
import React from 'react';
import {
  Button,
  Container,
  Row,
  Col,
  Media,
  Card,
  CardImg,
  CardText,
  CardTitle,
  ListGroup,
  ListGroupItem,
} from 'reactstrap';
// Web Icon fonts
import Icofont from 'react-icofont';
// Web theme color root css
import './components/colors.css';
import Tdsheader from './components/Tdsheader';
import Tdsbanner from './components/Tdsbanner';
import Tdsaboutslider from './components/Tdsaboutslider';
import Tdsfeedbackslider from './components/Tdsfeedbackslider';
import Tdslightbox from './components/Tdslightbox';
import Tdspricingtable from './components/Tdspricingtable';
import Tdsfooter from './components/Tdsfooter';
import Tdscontactform from './components/Tdscontactform';
import './App.css';
import './responsive.css';
import 'react-toastify/dist/ReactToastify.min.css';
import { Modal } from 'react-responsive-modal';
import { init } from 'emailjs-com';
init(process.env.REACT_APP_USER_ID);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab6: '1',
    };
  }
  toggle = (tab) => {
    if (this.state.activeTab6 !== tab) {
      this.setState({
        activeTab6: tab,
      });
    }
  };

  state = {
    openModal6: false,
  };

  onClickButton6 = (e) => {
    e.preventDefault();
    this.setState({ openModal6: true });
  };

  onCloseModal6 = () => {
    this.setState({ openModal6: false });
  };

  render() {
    return (
      <div className="App">
        <Tdsheader />
        <div id="home" className="section">
          <Tdsbanner />
        </div>
        <div className=" subscribe-box grapping">
          <Container>
            <Row className="align-items-center">
              <Col xs="12" md="12" lg="12" className="text-center">
                <h5 className="text-white">
                  {' '}
                  Join Africongo Worldwide for latest updates & news.
                </h5>
              </Col>
              {/* <Col xs="12" md="12" lg="4">
                <Tdsinputgroup />
              </Col> */}
            </Row>
          </Container>
        </div>
        {/* Welcome Section Start */}
        <div className=" welcome-box grapping">
          <Container>
            <div className="title-box">
              <h2>
                <b>Welcome to Africongo</b>
              </h2>
              <p>
                Africongo is a technology and engineering company focusing on
                online services and advertising, digital platforms, and
                artificial intelligence. We collaborate with both public and
                private sector businesses and companies, and enable
                entrepreneurs to leverage our products and services to quickly
                realize their projects.
              </p>
            </div>
            <Row>
              <Col xs="12" sm="12" md="4">
                <div className="white-bg wel-box coman-box">
                  <Icofont icon="icofont-drag2" />
                  <h4>Creative Design</h4>
                  <p>
                    Flawless design with customer's hearts in our mind to exceed
                    expectations and ensure endless satisfacion.
                  </p>
                </div>
              </Col>
              <Col xs="12" sm="12" md="4">
                <div className="white-bg wel-box coman-box">
                  <Icofont icon="icofont-code" />
                  <h4>Friendly Codes</h4>
                  <p>
                    Clear and concise coding to guarantee easy management and
                    maintenance even for novice coders.
                  </p>
                </div>
              </Col>
              <Col xs="12" sm="12" md="4">
                <div className="white-bg wel-box coman-box">
                  <Icofont icon="icofont-live-support" />
                  <h4>Fast 247 Support</h4>
                  <p>
                    With our worldwide support teams, we are at your service
                    whenever you need us 24/7 in all time zones.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* About us Section Start */}
        <div className="section about-box grapping" id="about">
          <Container>
            <Row>
              <Col xs="12" sm="12" md="12" lg="6">
                <div className="about-img">
                  <img
                    src={require('./images/540x342.jpg')}
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </Col>
              <Col xs="12" sm="12" md="12" lg="6">
                <Tdsaboutslider />
              </Col>
            </Row>
          </Container>
        </div>
        {/* Our Services Section Start */}
        <div className="section ourservice grapping" id="services">
          <Container>
            <div className="title-box">
              <h2>
                <b>SaaS & Services</b>
              </h2>
              <p>
                We offer the best in class services and products at your
                convenience.
              </p>
            </div>
            <Row>
              <Col xs="12" sm="6" md="6" lg="3">
                <div className="white-bg coman-box ourservice-box">
                  <Icofont icon="icofont-crown" />
                  <h4>Branding</h4>
                  {/* <p>There are many variations of passages of available.</p> */}
                </div>
              </Col>
              <Col xs="12" sm="6" md="6" lg="3">
                <div className="white-bg coman-box ourservice-box">
                  <Icofont icon="icofont-pen-alt-3" />
                  <h4>UX/UI Design</h4>
                  {/* <p>There are many variations of passages of available.</p> */}
                </div>
              </Col>
              <Col xs="12" sm="6" md="6" lg="3">
                <div className="white-bg coman-box ourservice-box">
                  <Icofont icon="icofont-camera-alt" />
                  <h4>Photography</h4>
                  {/* <p>There are many variations of passages of available.</p> */}
                </div>
              </Col>
              <Col xs="12" sm="6" md="6" lg="3">
                <div className="white-bg coman-box ourservice-box">
                  <Icofont icon="icofont-chart-growth" />
                  <h4>Marketing</h4>
                  {/* <p>There are many variations of passages of available.</p> */}
                </div>
              </Col>
              <Col xs="12" sm="6" md="6" lg="3">
                <div className="white-bg coman-box ourservice-box">
                  <Icofont icon="icofont-laptop-alt" />
                  <h4>Web Design</h4>
                  {/* <p>There are many variations of passages of available.</p> */}
                </div>
              </Col>
              <Col xs="12" sm="6" md="6" lg="3">
                <div className="white-bg coman-box ourservice-box">
                  <Icofont icon="icofont-tick-boxed" />
                  <h4>Development</h4>
                  {/* <p>There are many variations of passages of available.</p> */}
                </div>
              </Col>
              <Col xs="15" sm="6" md="6" lg="3">
                <div className="white-bg coman-box ourservice-box">
                  <Icofont icon="paper" />
                  <h4>Content Writer</h4>
                  {/* <p>There are many variations of passages of available.</p> */}
                </div>
              </Col>
              <Col xs="12" sm="6" md="6" lg="3">
                <div className="white-bg coman-box ourservice-box">
                  <Icofont icon="search-job" />
                  <h4>SEO & SMM</h4>
                  {/* <p>There are many variations of passages of available.</p> */}
                </div>
              </Col>
              <Col xs="12" sm="6" md="6" lg="3">
                <div className="white-bg coman-box ourservice-box">
                  <Icofont icon="search-job" />
                  <h4>ML & AI</h4>
                  {/* <p>There are many variations of passages of available.</p> */}
                </div>
              </Col>
              <Col xs="12" sm="6" md="6" lg="3">
                <div className="white-bg coman-box ourservice-box">
                  <Icofont icon="search-job" />
                  <h4>Blockchain</h4>
                  {/* <p>There are many variations of passages of available.</p> */}
                </div>
              </Col>
              <Col xs="12" sm="6" md="6" lg="3">
                <div className="white-bg coman-box ourservice-box">
                  <Icofont icon="search-job" />
                  <h4>Data Privacy</h4>
                  {/* <p>There are many variations of passages of available.</p> */}
                </div>
              </Col>{' '}
              <Col xs="12" sm="6" md="6" lg="3">
                <div className="white-bg coman-box ourservice-box">
                  <Icofont icon="search-job" />
                  <h4>Tech Security</h4>
                  {/* <p>There are many variations of passages of available.</p> */}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* Team Section Start */}
        <div className="section expert-team grapping" id="team">
          <Container>
            <div className="title-box">
              <h2>
                <b>Expert Team</b>
              </h2>
              <p>
                Our business requires precision and experience, and we are
                fortunate to have some of the best professionals available. Our
                team of specialists ensures that everything is carried out
                according to the terms of the contract. From design to
                completion, we work closely with our clients to guide them every
                step of the way. Discover some of our company's experts.
              </p>
            </div>
            <Row>
              <Col xs="12" sm="6" md="6" lg="4">
                <div className="text-center coman-box team-box">
                  <div className="team-img">
                    <img
                      src={require('./images/user2.jpg')}
                      alt=""
                      className="img-fluid rounded-circle"
                    />
                  </div>
                  <h4 className="text-white">Conscious</h4>
                  {/* <p className="text-white">Ingénieur système et technologue</p> */}
                  <ListGroup className="social-icon-team">
                    <ListGroupItem
                      tag="a"
                      href="https://www.facebook.com/consciouscongolese/"
                    >
                      {' '}
                      <Icofont icon="icofont-facebook" />
                    </ListGroupItem>
                    <ListGroupItem
                      tag="a"
                      href="https://twitter.com/conscious_congo"
                    >
                      {' '}
                      <Icofont icon="icofont-twitter" />
                    </ListGroupItem>
                    <ListGroupItem
                      tag="a"
                      href="https://www.instagram.com/consciouscongolese/"
                    >
                      {' '}
                      <Icofont icon="icofont-instagram" />
                    </ListGroupItem>
                    <ListGroupItem
                      tag="a"
                      href="https://www.linkedin.com/in/conscious-congolese-44a14b211"
                    >
                      {' '}
                      <Icofont icon="icofont-linkedin" />
                    </ListGroupItem>
                  </ListGroup>
                </div>
              </Col>
              <Col xs="12" sm="6" md="6" lg="4">
                <div className="text-center coman-box team-box">
                  <div className="team-img">
                    <img
                      src={require('./images/user1.jpg')}
                      alt=""
                      className="img-fluid rounded-circle"
                    />
                  </div>
                  <h4 className="text-white"> Martha </h4>
                  {/* <p className="text-white">
                    IT, Infographe et Administratice commerciale
                  </p> */}
                  <ListGroup className="social-icon-team">
                    <ListGroupItem
                      tag="a"
                      href="https://www.facebook.com/Martha.Likambo"
                    >
                      {' '}
                      <Icofont icon="icofont-facebook" />
                    </ListGroupItem>
                    <ListGroupItem tag="a" href="https://twitter.com/martah243">
                      {' '}
                      <Icofont icon="icofont-twitter" />
                    </ListGroupItem>
                    <ListGroupItem
                      tag="a"
                      href="https://www.instagram.com/la.likambo/"
                    >
                      {' '}
                      <Icofont icon="icofont-instagram" />
                    </ListGroupItem>
                    <ListGroupItem
                      tag="a"
                      href="https://www.linkedin.com/in/martha-djumila-likambo/"
                    >
                      {' '}
                      <Icofont icon="icofont-linkedin" />
                    </ListGroupItem>
                  </ListGroup>
                </div>
              </Col>
              <Col xs="12" sm="6" md="6" lg="4">
                <div className="text-center coman-box team-box">
                  <div className="team-img">
                    <img
                      src={require('./images/user3.jpg')}
                      alt=""
                      className="img-fluid rounded-circle"
                    />
                  </div>
                  <h4 className="text-white">Jeremie</h4>
                  {/* <p className="text-white">Ingénieur logiciel specialiste</p> */}
                  <ListGroup className="social-icon-team">
                    {/* <ListGroupItem tag="a" href="#">
                      {' '}
                      <Icofont icon="icofont-facebook" />
                    </ListGroupItem> */}
                    <ListGroupItem
                      tag="a"
                      href="https://twitter.com/jeremie_rdc"
                    >
                      {' '}
                      <Icofont icon="icofont-twitter" />
                    </ListGroupItem>
                    {/* <ListGroupItem tag="a" href="#">
                      {' '}
                      <Icofont icon="icofont-instagram" />
                    </ListGroupItem> */}
                    <ListGroupItem
                      tag="a"
                      href="https://www.linkedin.com/in/jeremie-biri-a6226a1b2/"
                    >
                      {' '}
                      <Icofont icon="icofont-linkedin" />
                    </ListGroupItem>
                  </ListGroup>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* Portfolio Section Start*/}
        <div className="section ourportfolio grapping" id="portfolio">
          <Container>
            <div className="title-box">
              <h2>
                <b>Our Portfolio</b>
              </h2>
              <p>
                Our work, products, and services speak for themselves, and our
                customers have not said otherwise. We strive to remain customer
                obsessed and beat and exceed all our customers expectations in
                record time.
              </p>
            </div>
            <Tdslightbox></Tdslightbox>
          </Container>
        </div>
        {/* Marketing Section Start*/}
        <div className="marketing-section grapping">
          <Container>
            <Row className="align-items-center">
              <Col xs="12" sm="12" md="12" lg="6">
                <div className="marketing-img">
                  <img
                    src={require('./images/540x352.jpg')}
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </Col>
              <Col xs="12" sm="12" md="12" lg="6">
                <Media className="markrt-box">
                  <Media left href="#">
                    <Icofont icon="icofont-web" />
                  </Media>
                  <Media body>
                    <Media heading>Fully Responsive</Media>A website is a more
                    effective way to present your business. Our way of
                    programming our sites, allows us to realize your ideas
                    without limit.
                  </Media>
                </Media>
                <Media className="markrt-box">
                  <Media left href="#">
                    <Icofont icon="icofont-chart-pie" />
                  </Media>
                  <Media body>
                    <Media heading>Digital Marketing</Media>
                    Thanks to your story, we will make it an ideal brand image
                    for your company, and create a brand image that will arouse
                    the interest of your customers.
                  </Media>
                </Media>
                <Media className="markrt-box">
                  <Media left href="#">
                    <Icofont icon="icofont-brand-designfloat" />
                  </Media>
                  <Media body>
                    <Media heading>Clean & Unique Design</Media>
                    We create unique, refined and attractive visual identities
                    and communication media in order to help our customers to be
                    instantly recognizable in their competitive environments.
                  </Media>
                </Media>
              </Col>
            </Row>
          </Container>
        </div>
        {/* Expert Feadback Section Start */}
        {/* <div className="expertfeesback grapping">
          <Container>
            <div className="title-box">
              <h2>
                <b>Expert Feadback</b>
              </h2>
              <p>
                Every testimony from experts in various sectors using our
                services enable us to set new standard and exceed customers
                expectation and guarantee their satisfaction.
              </p>
            </div>
            <Tdsfeedbackslider></Tdsfeedbackslider>
          </Container>
        </div> */}
        {/* Our  Pricing Section Start*/}
        <div className="section ourlatest-pricing grapping" id="price">
          <Container>
            <div className="title-box">
              <h2>
                <b>Funding and Pricing</b>
              </h2>
              <p>
                We provide the best market prices for our products and services
                while exceeding customers' expectations and ensuring their
                satisfaction.
              </p>
            </div>
            <Tdspricingtable></Tdspricingtable>
          </Container>
        </div>
        {/*Our Latest News  Section Start*/}
        <div className="section latestnews grapping" id="blog">
          <Container>
            <div className="title-box">
              <h2>
                <b>Latest News</b>
              </h2>
              <p>
                Here are the wonders that our products and services are doing
                for our customers throughout our global communities.
              </p>
            </div>
            <Row>
              <Col xs="12" sm="12" md="4">
                <Card>
                  <CardImg
                    top
                    width="100%"
                    src={require('./images/pic_23.jpg')}
                    alt="Card image cap"
                  />
                  <Card body>
                    <CardTitle>Convenient Services</CardTitle>
                    <CardText>
                      Thanks to our design team available at all times 24 hours
                      a day we create and implement your campaign in digital
                      formats.
                    </CardText>
                    {/* <Button color="primary">Read More</Button> */}
                  </Card>
                </Card>
              </Col>
              <Col xs="12" sm="12" md="4">
                <Card>
                  <CardImg
                    top
                    width="100%"
                    src={require('./images/pic_16.jpg')}
                    alt="Card image cap"
                  />
                  <Card body>
                    <CardTitle>Real-time Analysis</CardTitle>
                    <CardText>
                      Search engine optimization is Essential for creating a
                      brand image Efficient and successful in real time for your
                      satisfaction.
                    </CardText>
                    {/* <Button color="primary">Read More</Button> */}
                  </Card>
                </Card>
              </Col>
              <Col xs="12" sm="12" md="4">
                <Card>
                  <CardImg
                    top
                    width="100%"
                    src={require('./images/pic_21.jpg')}
                    alt="Card image cap"
                  />
                  <Card body>
                    <CardTitle>Digital Marketing</CardTitle>
                    <CardText>
                      Develop your customer base through the creation of
                      ultra-targeted databases and the institution a single
                      communication process.
                    </CardText>
                    {/* <Button color="primary">Read More</Button> */}
                  </Card>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        {/* Work With Us  Section Start*/}
        <div className="workwithus grapping">
          <Container>
            <div className="title-box mb-0">
              <h2 className="text-white">
                <b>Want to work and engage with Africongo?</b>
              </h2>
              <p className="text-white mb-4">
                Schedule a Quick Let's Talk (QLT)
              </p>
              {/* <Button>Get Started</Button> */}
              <Button onClick={this.onClickButton6}>Get Started</Button>
              <Modal open={this.state.openModal6} onClose={this.onCloseModal6}>
                <h6>Please connect with us now!</h6>
                <Tdscontactform
                  // selectorData={pricings}
                  // id={0}
                  // tier={0}
                  onClose={this.onCloseModal6}
                ></Tdscontactform>
              </Modal>
            </div>
          </Container>
        </div>
        {/* Contact Us Section Start  */}
        <div className="section contactus grapping" id="contact">
          <Container>
            <div className="title-box">
              <h2>
                <b>Contact Us</b>
              </h2>
              <p>Reach Africongo at your convenience.</p>
              <p>We provide 247 online support for businesses.</p>
              <p>
                We offer our customers tailored services to help them to carry
                out their project in an efficient and cost-effective manner. If
                you have questions about our previous achievements, or if you
                want to know our fields of activity, do not hesitate to contact
                us for more information.
              </p>
            </div>
            <Row>
              <Col xs="12" md="12" lg="4">
                <Media className="address-box">
                  <Media left href="#">
                    <Icofont icon="icofont-location-pin" />
                  </Media>
                  <Media body>
                    <Media heading>Address</Media>
                    <p>Avenue Colonel Ebeya, Kinshasa, RDC</p>
                    {/* <p>RDC</p> */}
                  </Media>
                </Media>
              </Col>
              <Col xs="12" md="6" lg="4">
                <Media className="address-box">
                  <Media left href="#">
                    <Icofont icon="icofont-mail" />
                  </Media>
                  <Media body>
                    <Media heading>Email</Media>
                    <a href="mailto:info@africongo.com">
                      {' '}
                      info@africongo.com
                    </a>{' '}
                    <p></p>
                    <br />
                    {/* <a href="mailto:techsupport@africongo.com">
                      techsupport@africongo.com
                    </a>
                    <a href="mailto:donate@africongo.com">
                      donate@africongo.com
                    </a> */}
                  </Media>
                </Media>
              </Col>
              <Col xs="12" md="6" lg="4">
                <Media className="address-box">
                  <Media left href="#">
                    <Icofont icon="icofont-phone" />
                  </Media>
                  <Media body>
                    <Media heading>Phone</Media>
                    <a href="tel:+243 973 594 876"> +243 973 594 876</a> <br />
                    {/* <a href="tel:+1 998 654 898"> +1 998 654 8984</a> <br />
                    <a href="tel:+44 5555 458">+44 553 553 458</a> */}
                    <a href="tel:+243 973 594 876"></a>
                    <p></p>
                  </Media>
                </Media>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs="12" md="12" lg="4">
                <h4>Get in Touch</h4>
                <hr />
                <p>For more information, connect with us on social networks:</p>
                <ListGroup className="social-icon">
                  <ListGroupItem
                    tag="a"
                    href="https://www.facebook.com/Africongo.cd"
                  >
                    {' '}
                    <Icofont icon="icofont-facebook" />
                  </ListGroupItem>
                  <ListGroupItem tag="a" href="https://twitter.com/Africongo3">
                    {' '}
                    <Icofont icon="icofont-twitter" />
                  </ListGroupItem>
                  <ListGroupItem
                    tag="a"
                    href="https://www.instagram.com/africongo.cd/"
                  >
                    {' '}
                    <Icofont icon="icofont-instagram" />
                  </ListGroupItem>
                  <ListGroupItem
                    tag="a"
                    href="https://www.linkedin.com/in/africongo-cd/"
                  >
                    {' '}
                    <Icofont icon="icofont-linkedin" />
                  </ListGroupItem>
                  <ListGroupItem
                    tag="a"
                    href="https://www.youtube.com/@africongo"
                  >
                    {' '}
                    <Icofont icon="icofont-youtube-play" />
                  </ListGroupItem>
                </ListGroup>
              </Col>

              <Col xs="12" md="12" lg="8">
                {/* <Form className="mt-4">
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Input
                          type="text"
                          name="name"
                          id="examplename"
                          placeholder="Name"
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Input
                          type="email"
                          name="email"
                          id="exampleEmail"
                          placeholder="Email"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup>
                    <Input
                      type="textarea"
                      name="address"
                      id="exampleAddress"
                      placeholder="Your message"
                    />
                  </FormGroup>

                  <Button color="primary">Send Message</Button>
                </Form> */}
                <Tdscontactform></Tdscontactform>
              </Col>
            </Row>
          </Container>
        </div>
        {/* Footer  Section Start */}

        <div className="">
          <Tdsfooter></Tdsfooter>
        </div>
      </div>
    );
  }
}

export default App;
