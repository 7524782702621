const pricings = [
    [
      {
        label: 'Become an Africongo customer at $2500 per month',
        value: 'Want to Become an Africongo customer at $2500 per month',
      },
      {
        label: 'Become an Africongo shareholder at $2500 per month',
        value: 'Want to Become an Africongo shareholder at $2500 per month',
      },
      {
        label: 'Make a sponsored donation',
        value: 'Want to Make a donation',
      },
    ],
    [
      {
        label: 'Become a standard Africongo customer at $7500 per month',
        value: 'Want to Become a standard Africongo customer at $7500 per month',
      },
      {
        label: 'Become a standard Africongo shareholder at $7500 per month',
        value: 'Want to Become a standard Africongo shareholder at $7500 per month',
      },
      {
        label: 'Make a sponsored donation',
        value: 'Want to Make a donation',
      },
    ],
    [
      {
        label: 'Become a premium Africongo customer at $22500 per month',
        value: 'Want to Become a premium Africongo customer at $22500 per month',
      },
      {
        label: 'Become a premium Africongo shareholder at $22500 per month',
        value: 'Want to Become a premium Africongo shareholder at $22500 per month',
      },
      {
        label: 'Make a sponsored donation',
        value: 'Want to Make a donation',
      },
    ],
    [
      {
        label: 'Become an Africongo customer at $30000 per year',
        value: 'Want to Become an Africongo customer at $30000 per year',
      },
      {
        label: 'Become an Africongo shareholder at $30000 per year',
        value: 'Want to Become an Africongo shareholder at $30000 per year',
      },
      {
        label: 'Make a sponsored donation',
        value: 'Want to Make a donation',
      },
    ],
    [
      {
        label: 'Become a standard Africongo customer at $90000 per year',
        value: 'Want to Become a standard Africongo customer at $90000 per year',
      },
      {
        label: 'Become a standard Africongo shareholder at $90000 per year',
        value: 'Want to Become a standard Africongo shareholder at $90000 per year',
      },
      {
        label: 'Make a sponsored donation',
        value: 'Want to Make a donation',
      },
    ],
    [
      {
        label: 'Become a premium Africongo customer at $270000 per year',
        value: 'Want to Become a premium Africongo customer at $270000 per year',
      },
      {
        label: 'Become a premium Africongo shareholder at $270000 per year',
        value: 'Want to Become a premium Africongo shareholder at $270000 per year',
      },
      {
        label: 'Make a sponsored donation',
        value: 'Want to Make a donation',
      },
    ],
  ];

export default pricings;
