import React from 'react';
import { Row, Col, Progress } from 'reactstrap';
import Icofont from 'react-icofont';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './Tdsaboutslider.css';

class Tdsaboutslider extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <OwlCarousel
        className="owl-theme"
        loop
        margin={10}
        nav={false}
        items="1"
        dots={true}
        lazyLoad={true}
      >
        <div className="item">
          <div className="title-box mb-0 text-left">
            <h2 className="mb-0">
              About <b>Africongo</b>
            </h2>
            <h4 className="mt-0 mb-2 sub-title">
              We Are Africa <span>Digital & Tech Solution</span>
            </h4>
            <p>
              Our mindset is focused on results for which we propose long-term
              solutions to achieve your goals.
            </p>
          </div>
          <Row className="mt-4">
            <Col className="text-left">
              <h6 className="mb-0">Branding</h6>
            </Col>
            <Col className="text-right">
              <h6 className="mb-0">100%</h6>
            </Col>
          </Row>
          <Progress value="100" animated color="orange" className="mb-3" />
          <Row>
            <Col className="text-left">
              <h6 className="mb-0">Marketing</h6>
            </Col>
            <Col className="text-right">
              <h6 className="mb-0">98%</h6>
            </Col>
          </Row>
          <Progress value={98} animated color="orange" className="mb-3" />
          <Row>
            <Col className="text-left">
              <h6 className="mb-0">Web Design</h6>
            </Col>
            <Col className="text-right">
              <h6 className="mb-0">95%</h6>
            </Col>
          </Row>
          <Progress value={95} animated color="orange" className="mb-3" />
          <Row>
            <Col className="text-left">
              <h6 className="mb-0">Web Development</h6>
            </Col>
            <Col className="text-right">
              <h6 className="mb-0">100%</h6>
            </Col>
          </Row>
          <Progress value={100} animated color="orange" />
        </div>

        <div className="item">
          <div className="title-box text-left mb-0">
            <h2 className="mb-0">
              Know About Our <b>Mission</b>
            </h2>
            <h4 className="mt-0 mb-2 sub-title">
              We Are Africa <span>Digital & Tech Solution</span>
            </h4>
          </div>
          <p>
            We are also performance consulting office for your projects as well
            as your digital marketing! Our goal is to combine a
            performance-oriented strategy, execution with digital creativity for
            the rise of your brand.
          </p>{' '}
          <p>
            We identify your needs and find the most effective solution through
            the creation of communication plans as well as campaign structures.{' '}
          </p>{' '}
          <p>
            We collaborate with a media team of digital experts working hand in
            hand by putting their core competencies to your online business.
          </p>
        </div>

        <div className="item">
          <div className="title-box text-left mb-0">
            <h2 className="mb-0">
              Why Choose <b>Us</b>
            </h2>
            <h4 className="mt-0 mb-2 sub-title">
              We Are Africa <span>Digital & Tech Solution</span>
            </h4>
            <p>
              Thanks to our design team, we create and implement your campaign
              in digital formats: display, native, social media, web design,
              graphic identity, web design, UX/UI analysis, video, motion
              design, design and ad production - whatever you need, we provide
              it.
            </p>
          </div>
          <div className="why-choose">
            <Row noGutters>
              <Col xs="6" sm="6" md="4">
                <div className="why-box text-center">
                  <Icofont icon="icofont-drag2" />
                  <h5>Perfect Design</h5>
                </div>
              </Col>
              <Col xs="6" sm="6" md="4">
                <div className="why-box text-center">
                  <Icofont icon="icofont-live-support" />
                  <h5>Perfect Design</h5>
                </div>
              </Col>
              <Col xs="6" sm="6" md="4">
                <div className="why-box text-center">
                  <Icofont icon="icofont-support" />
                  <h5>Friendly Support</h5>
                </div>
              </Col>
              <Col xs="6" sm="6" md="4">
                <div className="why-box text-center">
                  <Icofont icon="icofont-unique-idea" />
                  <h5>Unique Design</h5>
                </div>
              </Col>
              <Col xs="6" sm="6" md="4">
                <div className="why-box text-center">
                  <Icofont icon="icofont-team" />
                  <h5>Expert Team</h5>
                </div>
              </Col>
              <Col xs="6" sm="6" md="4">
                <div className="why-box text-center">
                  <Icofont icon="icofont-search-user" />
                  <h5>SEO & Marketing</h5>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </OwlCarousel>
    );
  }
}

export default Tdsaboutslider;
