import React, { useState } from 'react';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from 'reactstrap';
import classnames from 'classnames';
import './Tdspricingtable.css';
// import React, { Component } from 'react'
import { Modal } from 'react-responsive-modal';
import Tdscontactform from './Tdscontactform';
import Tdstier0 from './pricing/Tdstier0';
import Tdstier1 from './pricing/Tdstier1';
import Tdstier2 from './pricing/Tdstier2';
import Tdstier3 from './pricing/Tdstier3';
import Tdstier4 from './pricing/Tdstier4';
import Tdstier5 from './pricing/Tdstier5';
import 'react-responsive-modal/styles.css';
import 'react-toastify/dist/ReactToastify.min.css';
import { init } from 'emailjs-com';
init(process.env.REACT_APP_USER_ID);

class Tdspricingtable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1',
      activeTab1: '1',
      activeTab2: '1',
      activeTab3: '1',
      activeTab4: '1',
      activeTab5: '1',
    };
  }
  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
    if (this.state.activeTab1 !== tab) {
      this.setState({
        activeTab1: tab,
      });
    }
    if (this.state.activeTab2 !== tab) {
      this.setState({
        activeTab2: tab,
      });
    }
    if (this.state.activeTab3 !== tab) {
      this.setState({
        activeTab3: tab,
      });
    }
    if (this.state.activeTab4 !== tab) {
      this.setState({
        activeTab4: tab,
      });
    }
    if (this.state.activeTab5 !== tab) {
      this.setState({
        activeTab5: tab,
      });
    }
  };

  state = {
    openModal: false,
    openModal1: false,
    openModal2: false,
    openModal3: false,
    openModal4: false,
    openModal5: false,
  };

  onClickButton = (e) => {
    e.preventDefault();
    this.setState({ openModal: true });
  };

  onCloseModal = () => {
    this.setState({ openModal: false });
  };

  onClickButton1 = (e) => {
    e.preventDefault();
    this.setState({ openModal1: true });
  };

  onCloseModal1 = () => {
    this.setState({ openModal1: false });
  };

  onClickButton2 = (e) => {
    e.preventDefault();
    this.setState({ openModal2: true });
  };

  onCloseModal2 = () => {
    this.setState({ openModal2: false });
  };

  onClickButton3 = (e) => {
    e.preventDefault();
    this.setState({ openModal3: true });
  };

  onCloseModal3 = () => {
    this.setState({ openModal3: false });
  };

  onClickButton4 = (e) => {
    e.preventDefault();
    this.setState({ openModal4: true });
  };

  onCloseModal4 = () => {
    this.setState({ openModal4: false });
  };

  onClickButton5 = (e) => {
    e.preventDefault();
    this.setState({ openModal5: true });
  };

  onCloseModal5 = () => {
    this.setState({ openModal5: false });
  };

  render() {
    return (
      <div className="pricing-tab">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '1' })}
              onClick={() => {
                this.toggle('1');
              }}
            >
              Monthly
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '2' })}
              onClick={() => {
                this.toggle('2');
              }}
            >
              Yearly
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <Row>
              <Col md="4" lg="4">
                <div className="pricing-table" id={0}>
                  <div className="pricing-header">BASIC</div>
                  <div className="price">
                    <sup>$</sup>2500
                  </div>
                  <ul className="pricing-features">
                    <li>Become a Customer</li>
                    <li>Become a Shareholder</li>
                    {/* <li>2 GB Hosting</li>
                                        <li>2 Unique Users</li>
                                        <li>6 GB Capacity</li>
                                        <li>Weekly Backups</li>
                                        <li>5 Domain Names</li>
                                        <li>Enhanced Security</li>
                                        <li>Support 24/7 Hour</li> */}
                  </ul>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.onClickButton}
                  >
                    Get Started
                  </button>
                  <Modal
                    open={this.state.openModal}
                    onClose={this.onCloseModal}
                  >
                    <h6>Please engage with us now!</h6>
                    <Tdstier0
                      // selectorData={pricings}
                      // id={0}
                      // tier={0}
                      onClose={this.onCloseModal}
                    ></Tdstier0>
                  </Modal>
                </div>
              </Col>
              <Col md="4" lg="4">
                <div className="pricing-table active" id={1}>
                  <div className="pricing-header">STANDARD</div>
                  <div className="price">
                    <sup>$</sup>7500
                  </div>
                  <ul className="pricing-features">
                    <li>Become a Customer</li>
                    <li>Become a Shareholder</li>
                    {/* <li>5 GB Hosting</li>
                                        <li>2 Unique Users</li>
                                        <li>8 GB Capacity</li>
                                        <li>Weekly Backups</li>
                                        <li>8 Domain Names</li>
                                        <li>Enhanced Security</li>
                                        <li>Support 24/7 Hour</li> */}
                  </ul>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.onClickButton1}
                  >
                    Get Started
                  </button>
                  <Modal
                    open={this.state.openModal1}
                    onClose={this.onCloseModal1}
                  >
                    <h6>Please engage with us now!</h6>
                    <Tdstier1
                      // selectorData={pricings}
                      // id={0}
                      // tier={0}
                      onClose={this.onCloseModal1}
                    ></Tdstier1>
                  </Modal>
                </div>
              </Col>
              <Col md="4" lg="4">
                <div className="pricing-table" id={2}>
                  <div className="pricing-header">PREMIUM</div>
                  <div className="price">
                    <sup>$</sup>22500
                  </div>
                  <ul className="pricing-features">
                    <li>Become a Customer</li>
                    <li>Become a Shareholder</li>
                    {/* <li>10 GB Hosting</li>
                                        <li>2 Unique Users</li>
                                        <li>12 GB Capacity</li>
                                        <li>Weekly Backups</li>
                                        <li>15 Domain Names</li>
                                        <li>Enhanced Security</li>
                                        <li>Support 24/7 Hour</li> */}
                  </ul>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.onClickButton2}
                  >
                    Get Started
                  </button>
                  <Modal
                    open={this.state.openModal2}
                    onClose={this.onCloseModal2}
                  >
                    <h6>Please engage with us now!</h6>
                    <Tdstier2
                      // selectorData={pricings}
                      // id={0}
                      // tier={0}
                      onClose={this.onCloseModal2}
                    ></Tdstier2>
                  </Modal>
                </div>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <Col md="4" lg="4">
                <div className="pricing-table active" id={3}>
                  <div className="pricing-header">BASIC</div>
                  <div className="price">
                    <sup>$</sup>30000
                  </div>
                  <ul className="pricing-features">
                    <li>Become a Customer</li>
                    <li>Become a Shareholder</li>
                    {/* <li>10 GB Hosting</li>
                                        <li>2 Unique Users</li>
                                        <li>12 GB Capacity</li>
                                        <li>Weekly Backups</li>
                                        <li>15 Domain Names</li>
                                        <li>Enhanced Security</li>
                                        <li>Support 24/7 Hour</li> */}
                  </ul>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.onClickButton3}
                  >
                    Get Started
                  </button>
                  <Modal
                    open={this.state.openModal3}
                    onClose={this.onCloseModal3}
                  >
                    <h6>Please engage with us now!</h6>
                    <Tdstier3
                      // selectorData={pricings}
                      // id={0}
                      // tier={0}
                      onClose={this.onCloseModal3}
                    ></Tdstier3>
                  </Modal>
                </div>
              </Col>
              <Col md="4" lg="4">
                <div className="pricing-table" id={4}>
                  <div className="pricing-header">STANDARD</div>
                  <div className="price">
                    <sup>$</sup>90000
                  </div>
                  <ul className="pricing-features">
                    <li>Become a Customer</li>
                    <li>Become a Shareholder</li>
                    {/* <li>10 GB Hosting</li>
                                        <li>2 Unique Users</li>
                                        <li>12 GB Capacity</li>
                                        <li>Weekly Backups</li>
                                        <li>15 Domain Names</li>
                                        <li>Enhanced Security</li>
                                        <li>Support 24/7 Hour</li> */}
                  </ul>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.onClickButton4}
                  >
                    Get Started
                  </button>
                  <Modal
                    open={this.state.openModal4}
                    onClose={this.onCloseModal4}
                  >
                    <h6>Please engage with us now!</h6>
                    <Tdstier4
                      // selectorData={pricings}
                      // id={0}
                      // tier={0}
                      onClose={this.onCloseModal4}
                    ></Tdstier4>
                  </Modal>
                </div>
              </Col>
              <Col md="4" lg="4">
                <div className="pricing-table active" id={5}>
                  <div className="pricing-header">PREMIUM</div>
                  <div className="price">
                    <sup>$</sup>270000
                  </div>
                  <ul className="pricing-features">
                    <li>Become a Customer</li>
                    <li>Become a Shareholder</li>
                    {/* <li>10 GB Hosting</li>
                                        <li>2 Unique Users</li>
                                        <li>12 GB Capacity</li>
                                        <li>Weekly Backups</li>
                                        <li>15 Domain Names</li>
                                        <li>Enhanced Security</li>
                                        <li>Support 24/7 Hour</li> */}
                  </ul>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.onClickButton5}
                  >
                    Get Started
                  </button>
                  <Modal
                    open={this.state.openModal5}
                    onClose={this.onCloseModal5}
                  >
                    <h6>Please engage with us now!</h6>
                    <Tdstier5
                      // selectorData={pricings}
                      // id={0}
                      // tier={0}
                      onClose={this.onCloseModal5}
                    ></Tdstier5>
                  </Modal>
                </div>
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

export default Tdspricingtable;
