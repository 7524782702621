import { useForm } from 'react-hook-form';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import React from 'react';
import { Button, FormGroup } from 'reactstrap';
import pricings from './prices';
// const pricings = [
//   [
//     {
//       label: 'Become an Africongo customer at $2500 per month',
//       value: 'Want to Become an Africongo customer at $2500 per month',
//     },
//     {
//       label: 'Become an Africongo shareholder at $2500 per month',
//       value: 'Want to Become an Africongo shareholder at $2500 per month',
//     },
//     {
//       label: 'Make a sponsored donation',
//       value: 'Want to Make a donation',
//     },
//   ],
//   [
//     {
//       label: 'Become a standard Africongo customer at $7500 per month',
//       value: 'Want to Become a standard Africongo customer at $7500 per month',
//     },
//     {
//       label: 'Become a standard Africongo shareholder at $7500 per month',
//       value: 'Want to Become a standard Africongo shareholder at $7500 per month',
//     },
//     {
//       label: 'Make a sponsored donation',
//       value: 'Want to Make a donation',
//     },
//   ],
//   [
//     {
//       label: 'Become a premium Africongo customer at $22500 per month',
//       value: 'Want to Become a premium Africongo customer at $22500 per month',
//     },
//     {
//       label: 'Become a premium Africongo shareholder at $22500 per month',
//       value: 'Want to Become a premium Africongo shareholder at $22500 per month',
//     },
//     {
//       label: 'Make a sponsored donation',
//       value: 'Want to Make a donation',
//     },
//   ],
//   [
//     {
//       label: 'Become an Africongo customer at $30000 per year',
//       value: 'Want to Become an Africongo customer at $30000 per year',
//     },
//     {
//       label: 'Become an Africongo shareholder at $30000 per year',
//       value: 'Want to Become an Africongo shareholder at $30000 per year',
//     },
//     {
//       label: 'Make a sponsored donation',
//       value: 'Want to Make a donation',
//     },
//   ],
//   [
//     {
//       label: 'Become a standard Africongo customer at $90000 per year',
//       value: 'Want to Become a standard Africongo customer at $90000 per year',
//     },
//     {
//       label: 'Become a standard Africongo shareholder at $90000 per year',
//       value: 'Want to Become a standard Africongo shareholder at $90000 per year',
//     },
//     {
//       label: 'Make a sponsored donation',
//       value: 'Want to Make a donation',
//     },
//   ],
//   [
//     {
//       label: 'Become a premium Africongo customer at $270000 per year',
//       value: 'Want to Become a premium Africongo customer at $270000 per year',
//     },
//     {
//       label: 'Become a premium Africongo shareholder at $270000 per year',
//       value: 'Want to Become a premium Africongo shareholder at $270000 per year',
//     },
//     {
//       label: 'Make a sponsored donation',
//       value: 'Want to Make a donation',
//     },
//   ],
// ];
function Tdstier0(props) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // Function that displays a success toast on bottom right of the page when form submission is successful
  const toastifySuccess = () => {
    toast('Form sent!', {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      className: 'submit-feedback success',
      toastId: 'notifyToast',
    });
  };

  // Function called on submit that uses emailjs to send email of valid contact form
  const onSubmit = async (data) => {
    // Destrcture data object
    const { name, email, subject, message } = data;
    try {
      const templateParams = {
        name,
        email,
        subject,
        message,
      };

      await emailjs.send(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_USER_ID
      );

      reset();
      // props.onClose ? props.onClose() : console.log();

      toastifySuccess();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="ContactForm">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <div className="contactForm">
              <form id="tdstier0" onSubmit={handleSubmit(onSubmit)} noValidate>
                <FormGroup>
                  {/* Row 1 of form */}
                  <div className="row formRow">
                    <div className="col-6">
                      <input
                        type="text"
                        name="name"
                        {...register('name', {
                          required: {
                            value: true,
                            message: 'Please enter your name',
                          },
                          maxLength: {
                            value: 30,
                            message: 'Please use 30 characters or less',
                          },
                        })}
                        className="form-control formInput"
                        placeholder="Name"
                      ></input>
                      {errors.name && (
                        <span className="errorMessage">
                          {errors.name.message}
                        </span>
                      )}
                    </div>

                    <div className="col-6">
                      <input
                        type="email"
                        name="email"
                        {...register('email', {
                          required: true,
                          pattern:
                            /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                        })}
                        className="form-control formInput"
                        placeholder="Email address"
                      ></input>
                      {errors.email && (
                        <span className="errorMessage">
                          Please enter a valid email address
                        </span>
                      )}
                    </div>
                  </div>
                </FormGroup>
                <FormGroup>
                  {/* Row 2 of form */}
                  <div className="row formRow">
                    <div className="col">
                      <select
                        type="text"
                        name="subject"
                        {...register('subject', {
                          required: {
                            value: true,
                            message: 'Please enter a subject selectorData',
                          },
                          maxLength: {
                            value: 75,
                            message: 'Subject cannot exceed 75 characters',
                          },
                        })}
                        className="form-control formInput"
                        placeholder="Subject"
                      >
                        <option value="⬇️ Please select a subject ⬇️">
                          {' '}
                          ⬇️ Please select a subject ⬇️{' '}
                        </option>
                        {pricings[0].map((subject) => (
                          <option value={subject.value}>{subject.label}</option>
                        ))}
                        {/* <option value="grapefruit">Grapefruit</option>
                          <option value="lime">Lime</option>
                          <option value="coconut">Coconut</option>
                          <option value="mango">Mango</option> */}
                      </select>
                      {errors.subject && (
                        <span className="errorMessage">
                          {errors.subject.message}
                        </span>
                      )}
                    </div>
                  </div>
                </FormGroup>

                <FormGroup>
                  {/* Row 3 of form */}
                  <div className="row formRow">
                    <div className="col">
                      <textarea
                        rows={3}
                        name="message"
                        {...register('message', {
                          required: true,
                        })}
                        className="form-control formInput"
                        placeholder="Message"
                      ></textarea>
                      {errors.message && (
                        <span className="errorMessage">
                          Please enter a message
                        </span>
                      )}
                    </div>
                  </div>
                </FormGroup>
                <Button
                  color="primary"
                  className="submit-btn"
                  type="submit"
                  onClick={props.onClose}
                >
                  Send Message
                </Button>
              </form>
            </div>
            <ToastContainer />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tdstier0;
