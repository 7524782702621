import React from 'react';
import { Container, Button } from 'reactstrap';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Tdsvideomodal from './Tdsvideomodal';

import './Tdsbanner.css';
class Tdsbanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      responsive: {
        0: {
          nav: false,
          dots: true,
        },
        767: {
          nav: true,
        },
      },
    };
  }

  render() {
    return (
      <section className="banner">
        <OwlCarousel
          className="banner-owl owl-theme"
          loop={true}
          margin={0}
          autoplay={true}
          autoplayTimeout={3000}
          smartSpeed={500}
          nav={true}
          items="1"
          responsive={this.state.responsive}
          dots={false}
        >
          <div className="item">
            <div className="banner-one banner-img">
              <Container>
                <div className="banner-text text-center">
                  <h1>
                    THE BEST <span>Engineering</span> Solutions
                  </h1>
                  <p>
                    We identify your needs and engineer the most cost-effective
                    solution tailored to your requirements and budget.
                  </p>
                  <div className="d-flex justify-content-center">
                    {/* <Button color="primary mr-3">Get Started</Button> */}
                    {/* <Tdsvideomodal></Tdsvideomodal> */}
                  </div>
                </div>
              </Container>
            </div>
          </div>
          <div className="item">
            <div className="banner-two banner-img">
              <Container>
                <div className="banner-text text-center">
                  <h1>
                    THE BEST <span>Digital & Tech</span> Solutions
                  </h1>
                  <p>
                    Thanks to our design team, we create and implement your
                    campaign in digital formats: display, native, social media,
                    web design, graphic identity, web design, UX / UI analysis,
                    video motion design, design and ad production. We run and
                    digitize services while engineering sustainable products.
                  </p>
                  <div className="d-flex justify-content-center">
                    {/* <Button color="primary mr-3">Get Started</Button>{' '} */}
                    {/* <Tdsvideomodal></Tdsvideomodal> */}
                  </div>
                </div>
              </Container>
            </div>
          </div>
          <div className="item">
            <div className="banner-three banner-img">
              <Container>
                <div className="banner-text text-center">
                  <h1>
                    THE BEST <span>Marketing & Branding</span> Solutions
                  </h1>
                  <p>
                    By creating marketing strategies to meet all your business
                    objectives, we employ state of the art technologies and
                    services to ensure your satisfaction even through Google,
                    Facebook, Native etc. Data-driven marketing analytics:
                    Google Analytics reports, Facebook Business Manager, Google
                    Data Studio, Tableau. Writing, configuring and optimizing
                    Google Ads search campaigns, Google Ads Display and video
                    (YouTube) campaigns, Facebook and Instagram campaigns,
                    LinkedIn. Experts in affiliate marketing.
                  </p>
                  <div className="d-flex justify-content-center">
                    {/* <Button color="primary mr-3">Get Started</Button>{' '} */}
                    {/* <Tdsvideomodal></Tdsvideomodal> */}
                  </div>
                </div>
              </Container>
            </div>
          </div>
        </OwlCarousel>
      </section>
    );
  }
}

export default Tdsbanner;
